@import "./utils/sassMixins/colors";
@import "./utils/sassMixins/functions";

html,
body,
#root {
    margin: 0;
    border: 0;
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;
    width: 100% !important;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    overflow: auto;
    -webkit-overflow-scrolling: touch !important;
}

.BrainhubCarousel__track,
.BrainhubCarousel,
.BrainhubCarousel__trackContainer,
.BrainhubCarousel__container {
    height: 100%;
}

a {
    color: inherit !important;
    text-decoration: inherit !important;
}

.gap-0 {
    gap: 0 !important
}

.gap-1 {
    gap: .25rem !important
}

.gap-2 {
    gap: .5rem !important
}

.gap-3 {
    gap: 1rem !important
}

.gap-4 {
    gap: 1.5rem !important
}

.gap-5 {
    gap: 3rem !important
}

.react-cookie-law-dialog {
    bottom: 0px;
    top: unset !important;
    background: white;
    padding: 10px !important;

    .react-cookie-law-msg {
        white-space: break-spaces;
        padding: 10px !important;
    }

    .react-cookie-law-manage-btn {
        display: none !important;
    }
}

.nav-tabs {
    overflow-x: auto;
    overflow-y: hidden;
    display: -webkit-box;
    display: -moz-box;
    margin-bottom: -1px;

    &::-webkit-scrollbar {
        display: none;
    }
}

.nav-tabs>li {
    float: none;
}

.rccs {
    min-width: 270px !important;
    max-width: 290px !important;
    width: 100% !important;

    .rccs__card {
        width: 100% !important;
    }
}

.o-0 {
    opacity: .0;
}

.o-1 {
    opacity: .1;
}

.o-2 {
    opacity: .2;
}

.o-3 {
    opacity: .3;
}

.o-4 {
    opacity: .4;
}

.o-5 {
    opacity: .5;
}

.o-6 {
    opacity: .6;
}

.o-7 {
    opacity: .7;
}

.o-8 {
    opacity: .8;
}

.o-9 {
    opacity: .9;
}

.o-10 {
    opacity: 1;
}

* {
    box-sizing: border-box;
}

.modal-backdrop {
    z-index: 1050 !important;
}

.ant-message {
    z-index: 2000;
}

.modal {
    position: absolute !important;
    z-index: 1050;
}

.card-header {
    min-height: 40px;
}

.text-overflow-ellipsis {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.display-block {
    display: block !important;
}

.white-space-no-wrap {
    white-space: nowrap !important;
}

.white-space-pre {
    white-space: pre-line !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-default {
    cursor: default !important;
}

.height-auto {
    height: auto !important;
}

.height-100 {
    height: 100% !important;
}

.no-border {
    border: none !important;
}

.no-focus-shadow {
    &:focus {
        box-shadow: none !important;
    }
}

.m-auto {
    margin: auto !important;
}

.w-fit {
    width: fit-content !important;
}

.w-min {
    width: min-content !important;
}

.bg-white {
    background-color: white;
}

.pos-relative {
    position: relative !important;
}

.pos-absolute {
    position: absolute !important;
}

.pointer-events-none {
    pointer-events: none !important;
}

.pointer-events-all {
    pointer-events: all !important;
}

.display-none {
    display: none !important;
}

.spining {
    animation-name: spin;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.payAttention {
    animation-name: payAttention;
    animation-duration: .5s;
    animation-timing-function: linear;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}

.payAttentionHorizontal {
    animation-name: payAttentionHorizontal;
    animation-duration: .5s;
    animation-timing-function: linear;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}

.pb-chatButton {
    padding-bottom: 85px !important;
}

.dropdown-menu {
    max-height: 200px;
    overflow: auto;
}

.overflow-auto {
    overflow: auto;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-y-visible {
    overflow-y: visible;
}

.overflow-x-auto {
    overflow-x: auto;
}

.loadingDiv {
    position: relative;
}

.loadingDiv>* {
    pointer-events: none;
}

.loadingDiv::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .8);
    z-index: 99999;
}

@keyframes floatingTop {
    to {
        transform: translateY(-20px)
    }
}

@keyframes payAttention {
    0% {
        opacity: .8;
        transform: translateY(0);
    }

    50% {
        opacity: 1;
        transform: translateY(-10px);
    }
}

@keyframes payAttentionHorizontal {
    0% {
        margin-left: 0;
    }

    50% {
        margin-left: -10px;
    }
}

@keyframes spin {
    to {
        transform: rotate(360deg)
    }
}